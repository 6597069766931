.base::-webkit-scrollbar {
    display: none;
}

a {
    color: #ff0099;
}

.form-container-container {
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
}

.form-container {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    top: 40px;
    border: 1px solid #333;
    border-radius: 5px;
    background-color: #222;
    color: #fff;
}

.base::-webkit-scrollbar {
    background: #000000;
    display: none;
}

/* .form-container::-webkit-scrollbar-thumb {
    background: #393812;
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

.form-container::-webkit-scrollbar-corner {
    background: #000;
} */

.input-field,
.textarea-field,
.select-field {
    width: 100%;
    padding: 8px;
    margin: auto;
    margin-bottom: 10px;
    border: 1px solid #333;
    border-radius: 4px;
    background-color: #333;
    color: #fff;
    box-sizing: border-box;
}

.select-field:disabled {
    pointer-events: fill;
}

.input-field:not(:focus):not(:placeholder-shown):invalid {
    border: 2px solid red
}

div ul li {
    margin-top: 10px;
}

ul li ul li {
    margin-top: 3px;
    font-size: 12px;
}

ul li ol li {
    margin-top: 3px;
    font-size: 12px;
}

ul ol{
    margin-bottom: 3px;
}
ul ul{
    margin-bottom: 3px;
}

li:target {
    animation: highlight 3s;
}

@keyframes highlight {
    from {
        background-color: rgb(255, 255, 216, 0.185);
    }
}

strong {
    font-size: 2.2ex;
}

.textarea-field {
    height: 100px;
    /* Set the desired height */
    resize: none;
    /* Allow vertical resizing */
}

::-webkit-scrollbar {
    background: #242424;
}

::-webkit-scrollbar-thumb {
    background: #575757;
    -webkit-border-radius: 2ex;
}

::-webkit-scrollbar-thumb:hover {
    background: #494949;
    -webkit-border-radius: 2ex;
}

.textarea-field::placeholder .input-field::placeholder,
.select-field option {
    color: #ccc;
}

.submit-button {
    background-color: #00c9d4;
    margin: auto;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.submit-button:disabled {
    background-color: #01565b;
    color: #9b9b9b;
    cursor: not-allowed;
}

.help-button {
    background-color: #ff0099;
    margin: auto;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.plus-minus {
    --primary: #ff0099;
    --secondary: #222;
    --duration: .5s;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    mask-image: -webkit-radial-gradient(white, black);
    outline: none;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transform-style: preserve-3d;
    perspective: 240px;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    border: 4px solid var(--primary);
    background-size: 300% 300%;
    transition: transform .3s;
    transform: scale(var(--scale, 1)) translateZ(0);
    animation: var(--name, unchecked) var(--duration) ease forwards;

    &:before,
    &:after {
        content: '';
        position: absolute;
        width: 16px;
        height: var(--height, 16px);
        left: 6px;
        top: var(--top, 6px);
        background: var(--background, var(--secondary));
        animation: var(--name-icon-b, var(--name-icon, unchecked-icon)) var(--duration) ease forwards;
    }

    &:before {
        clip-path: polygon(0 6px, 6px 6px, 6px 0, 10px 0, 10px 6px, 16px 6px, 16px 10px, 10px 10px, 10px 16px, 6px 16px, 6px 10px, 0 10px);
    }

    &:after {
        --height: 4px;
        --top: 12px;
        --background: var(--primary);
        --name-icon-b: var(--name-icon-a, checked-icon);
    }

    &:active {
        --scale: .95;
    }

    &:checked {
        --name: checked;
        --name-icon-b: checked-icon;
        --name-icon-a: unchecked-icon;
    }
}

@keyframes unchecked-icon {
    from {
        transform: translateZ(12px);
    }

    to {
        transform: translateX(16px) rotateY(90deg) translateZ(12px);
    }
}

@keyframes checked-icon {
    from {
        transform: translateX(-16px) rotateY(-90deg) translateZ(12px);
    }

    to {
        transform: translateZ(12px);
    }
}

@keyframes unchecked {
    from {
        background-image: radial-gradient(ellipse at center, var(--secondary) 0%, var(--secondary) 25%, var(--primary) 25.1%, var(--primary) 100%);
        background-position: 100% 50%;
    }

    to {
        background-image: radial-gradient(ellipse at center, var(--secondary) 0%, var(--secondary) 25%, var(--primary) 25.1%, var(--primary) 100%);
        background-position: 50% 50%;
    }
}

@keyframes checked {
    from {
        background-image: radial-gradient(ellipse at center, var(--primary) 0%, var(--primary) 25%, var(--secondary) 25.1%, var(--secondary) 100%);
        background-position: 100% 50%;
    }

    to {
        background-image: radial-gradient(ellipse at center, var(--primary) 0%, var(--primary) 25%, var(--secondary) 25.1%, var(--secondary) 100%);
        background-position: 50% 50%;
    }
}

table {
    width: 100%;
    /* Optional */
}

tbody td,
thead th {
    width: 20%;
    /* Optional */
}